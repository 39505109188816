<template>

  <div
      class="f6 h-100 material-icons-loaded bg-concord-near-white concord-dark-gray fw4 h-100 mh0 pa0 roboto  ember-application application   pace-done"
      style="height: 100vh; overflow: hidden">
    <div class="h-100 h-100 flex flex-column">
      <div class="flex h-100">
        <contract-setting-left-menu></contract-setting-left-menu>
        <div class="h-100 flex-auto text-align-left">
          <div class="flex flex-column h-100 w-100 pl1 pr4">
            <header class="pv3">
              <div class="mt2 pt1 flex justify-between items-start">
                <div class="pr4">
                  <h1 class="fw4 f4 gray mv0 pr4 flex items-center size-medium">
                    数据字典
                  </h1>
                  <p class="fw4 mv3 pre-line silver text-align-left">添加并管理数据字典</p>
                </div>

              </div>
            </header>
            <div class="data-container shadow" style="flex: 1">
              <MetaListTable
                  :table-data="data"
                  :table-height="tableHeight"
                  :show-checkbox="true"
              >
                <template #header>
                  <div class="action">
                    <div class="selector-wrap">
                      <MetaListSelector
                          :list="data"
                          :show-operation-action-btn="false"
                      ></MetaListSelector>
                    </div>
                  </div>
                  <el-button class="addData-btn" @click="addItemDialogVisible=true">添加数据</el-button>

                </template>
                <el-table-column
                    prop="name"
                    label="名称"
                >
                </el-table-column>
                <el-table-column
                    label="值"

                    show-overflow-tooltip
                >
                  <template #default="scope">
                    <el-input v-if="scope.row.type==='text'" v-model="scope.row.value" @blur="saveData"></el-input>
                    <el-switch v-else-if="scope.row.type==='switch'" v-model="scope.row.value"></el-switch>
                    <template v-if="scope.row.type=='text-array'">
                      <el-tag v-for="(item,index) in scope.row.value" :key="index" style="margin-right: 10px">{{ item }}
                      </el-tag>
                    </template>

                  </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    fixed="right"
                    width="180px"
                >
                  <template #default="scope">
                    <el-button @click="infoDialogVisible=true;currentItem=scope.row">详情</el-button>
                    <el-button type="danger" @click="deleteItem(scope.$index)">删除</el-button>
                  </template>
                </el-table-column>
              </MetaListTable>
            </div>
          </div>

          <el-dialog title="提示" v-model="addItemDialogVisible">
            <div class="data-dialog">

              <div style="margin-bottom: 10px">
                <label>名称：</label>
                <el-input placeholder="请输入数据名称" style="width: 80%" v-model="name"></el-input>
              </div>

              <div style="margin-bottom: 10px">
                <label>类型：</label>
                <el-select v-model="type" style="margin-right: 10px">
                  <el-option v-for="item in dataTypes" :key="item.value" :label="item.label" :value="item.value">

                  </el-option>
                </el-select>
              </div>

              <div style="margin-bottom: 10px" v-if="type==='text'">
                <label>内容：</label>
                <el-input placeholder="请输入对应的值" style="width: 80%" v-model="value" :rows="3" type="textarea"></el-input>
              </div>
              <div style="margin-bottom: 10px" v-if="type==='switch'">
                <label>选择：</label>
                <el-switch v-model="value"></el-switch>
              </div>

              <div style="margin-bottom: 10px; padding:10px;border: 1px solid #e3e3e3;text-align: center"
                   v-if="type==='text-array'">

                <div v-for="(item,index) in textArray" :key="index" style="margin-bottom: 10px">

                  <el-input placeholder="请输入对应的值" style="width: 80%" v-model="textArray[index]"></el-input>
                  <el-button type="danger" @click="textArray.splice(index,1)">删除</el-button>
                </div>
                <el-button @click="textArray.push('')">增加一项</el-button>
              </div>

            </div>
            <template #footer>
      <span class="dialog-footer">
        <el-button @click="addItemDialogVisible=false">取消</el-button>
      <el-button type="primary" @click="addItemDialogVisible=false;addItem()"
                 :disabled="name=='' || type=='' || (type!='text-array' && value== null) || (type=='text-array' && textArray.length==0)">添加</el-button>
      </span>
            </template>

          </el-dialog>
          <el-dialog v-model="infoDialogVisible" @close="saveData">
            <div style="margin-bottom: 10px">
              <label>名称：</label>
              <el-input placeholder="请输入数据名称" style="width: 80%" v-model="currentItem.name"></el-input>
            </div>


            <div style="margin-bottom: 10px;display: flex;align-items: flex-start;justify-content: center"
                 v-if="currentItem.type==='text'">
              <label>内容：</label>
              <el-input placeholder="请输入对应的值" style="width: 80%" v-model="currentItem.value" :rows="3"
                        type="textarea"></el-input>
            </div>
            <div style="margin-bottom: 10px" v-if="currentItem.type==='switch'">
              <label>选择：</label>
              <el-switch v-model="currentItem.value"></el-switch>
            </div>

            <div style="margin-bottom: 10px; padding:10px;border: 1px solid #e3e3e3;text-align: center"
                 v-if="currentItem.type==='text-array'">

              <div v-for="(item,index) in currentItem.value" :key="index" style="margin-bottom: 10px">

                <el-input placeholder="请输入对应的值" style="width: 80%" v-model="currentItem.value[index]"></el-input>
                <el-button type="danger" @click="currentItem.value.splice(index,1)">删除</el-button>
              </div>
              <el-button @click="currentItem.value.push('')">增加一项</el-button>
            </div>
          </el-dialog>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import ContractSettingLeftMenu from "../components/ContractSettingLeftMenu";
import {getDataDictionary, saveDataDictionary} from "../api/api";
import {ElMessage} from "element-plus";
import MetaListTable from "../components/list/MetaListTable";
import MetaListSelector from "../components/list/MetaListSelector";
export default {
  name: "DataDictionary",
  components: {ContractSettingLeftMenu,MetaListTable,MetaListSelector},
  data() {

    return {
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      data: [],
      oldData: [],
      changed: false,
      addItemDialogVisible: false,
      dataTypes: [
        {value: 'text', label: '文本型'},
        {value: 'switch', label: '布尔型'},
        {value: 'text-array', label: '文本数组'}
      ],
      type: '',
      name: '',
      value: null,
      textArray: [],
      currentItem: null,
      infoDialogVisible: false,
      tableHeight: document.body.clientHeight - 150,


    }
  },
  watch: {
    data: {
      handler(newValue, oldValue) {
        if (JSON.stringify(this.oldData) !== JSON.stringify(newValue)) {
          this.changed = true;
        } else {
          this.changed = false
        }
      },
      deep: true //true 深度监听
    }
  },
  mounted() {
    getDataDictionary(this.userInfo.mainSiteTeamId).then(res => {
      if (res.status == 200) {
        if (res.data.data != null) {
          console.log(JSON.parse(res.data.data))
          this.data = JSON.parse(res.data.data)
          this.oldData = JSON.parse(res.data.data)
        }
      }
    })
    window.onresize=()=>{
      this.tableHeight=document.body.clientHeight - 150
    }
  },
  methods: {
    addItem: function () {

      if (this.type == 'text-array') {
        this.value = this.textArray;
      }

      this.data.push({name: this.name, type: this.type, value: this.value});

      this.name = '';
      this.type = '';
      this.value = null;
      this.textArray = []
      this.saveData();


    },
    saveData: function () {
      saveDataDictionary(this.userInfo.mainSiteTeamId, JSON.stringify(this.data)).then(res => {
        if (res.status == 200) {
          if (res.data.code == 0) {


            this.oldData = JSON.parse(JSON.stringify(this.data));
            this.changed = false;
            ElMessage.success("保存成功")
          } else {
            ElMessage.error("保存失败")
          }
        }
      }).catch(e => {
        ElMessage.error("网络错误")
        console.error(e);
      })
    },
    deleteItem: function (index) {
      this.data.splice(index, 1);
      this.saveData();
    }
  }


}
</script>

<style>

.data-container {
  text-align: left;

  background: white;
}


.data-dialog {
  text-align: left;

}

.el-dialog__header {
  text-align: left;
}

.addData-btn{
  height: 32px;
  box-sizing: border-box;
  min-height: unset;
  line-height: 6px;
  margin-left: 5px;
}
</style>
